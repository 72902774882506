<template>
	<div class="outer-wrapper filters"> 
		
		<div id="top-nav"> 
			<router-link :to="{ name: 'Flights Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="24px" src="@/assets/filters/duration.png" />
				<h1 class="heading6">Duration</h1>
			</div>
			
			<RangeSlider 
				class="slider"
				minValue=0
				:maxValue="filterOptions.maxDuration"
				:incrementValue="15"
				:formatFunction="formatTimeRange"
				v-model="selectedMaxDuration"

				@change="update()"
			/>

		</div>

		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Flights Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import helpers from '@/helpers/helpers.js';
import RangeSlider from '@/components/RangeSlider.vue';
import router from '@/router';

export default {
    data () {
        return {
			selectedMaxDuration: false,
        }
	},
	components: {
		RangeSlider,
	},
   computed: {
		...mapState({
			searchFilters: state => state.flightsSearch.searchFilters,
			searchResults: state => state.flightsSearch.searchResults,
		}),
		...mapGetters({
			filterOptions: 'flightsSearch/getFilterOptions',
		}),
	},
    methods: {
		formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
		update() { // If the selected duration == max duration, then turn filter off
			this.searchFilters.maxDuration = this.selectedMaxDuration;
			if(this.filterOptions.maxDuration == this.selectedMaxDuration) {
				this.searchFilters.maxDuration = false;
			}
		}
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Flights Results',
			})
		}
		this.selectedMaxDuration = this.searchFilters.maxDuration;
	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}

	.slider {
		margin-top: 20px;
	}
	
</style>